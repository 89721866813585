import Head from 'next/head';
import Link from 'next/link';

import styles from './404.module.scss';

const Custom404 = () => {
  return (
    <>
      <div className={styles.container}>
        <Head>
          <title>Not found | hundo</title>
          <link rel="icon" href="/hundo-favicon.png" />
        </Head>

        <main>
          <div className={styles['inner-container']}>
            <h1>ERROR 404</h1>
            <p className={styles.description}>PAGE NOT FOUND</p>
            <p>We&apos;re sorry,</p>
            <p>The page you&apos;re looking for is not available for one of the following reasons:</p>
            <ul>
              <li>The link to this page may be incorrect or out-of-date.</li>
              <li>You may have bookmarked a page that has been moved.</li>
            </ul>
            <p>
              Please go back to the <Link href="/profile">Profile</Link> page or{' '}
              <a href="mailto:support@hundo.xyz?subject=Support request (C404)" target="_blank" rel="noreferrer">
                contact us
              </a>{' '}
              for help.
            </p>
          </div>
        </main>
      </div>
      <div className={styles.background}>
        <div className={styles.grid} />
      </div>
    </>
  );
};

export default Custom404;
